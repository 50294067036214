export const appConfig = {
    ...JSON.parse((document.getElementById('inputHiddenForAppConfig') as HTMLInputElement).value),
    ...{
        "googleMapSetting": {
            "apiKey": "AIzaSyDVpWKo8BfojulFDsVvgiz469YoSuOHFP0",
            "locations": [
                {
                    "position": {
                        "lat": 1.3637087,
                        "lng": 103.8545555
                    },
                    "url": "https://www.google.com/maps/place/443+Ang+Mo+Kio+Ave+10,+06%E6%96%B0%E5%8A%A0%E5%9D%A1+560443/@1.3637087,103.8545555,17z/data=!4m5!1m2!2m1!1sBlk+443,+Ang+Mo+Kio+Ave+10,+%2306-1225+Singapore.!3m1!1s0x31da16e2dee7ce01:0xbca7ca3d572cef0"
                }
            ],
            "zoom": 18,
            "center": {
                "lat": 1.3637087,
                "lng": 103.8545555
            },
            "styles": [
                
            ]
        }
    }

}

