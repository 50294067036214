import { setupPixel } from '../../src/module/facebook-pixel';
import { setupGA } from '../../src/module/google-analytics';
import { slideToggle } from '../../src/module/dom';
import { setupExtension } from '../../src/module/extensions';
import { appConfig } from '../../src/app-config';
import { setupGoogleMap } from '../../src/module/google-map';
import * as $ from "jquery";
import { setupContactForm } from './Component/ContactForm/contact-form';

export function setupLayout() {
    setupPixel(appConfig.facebookPixelId);
    setupGA(appConfig.googleAnalyticsId, {
        Id: appConfig.googleAdsId,
        convertor: {
            'hpLink': 'HnbnCNei-aQBENbKl9sC',
            'whatsApp': 'JQGvCMr_3KUBENbKl9sC',
            'mapLink': '_iySCL280aUBENbKl9sC',
            'emailLink': 'cTE6CMfI_aQBENbKl9sC',
            'contactForm': 'f-abCM_N_aQBENbKl9sC',
            //'liveChat': '_txPCI--0aUBENbKl9sC'
        }
    });
    setupContactForm('footerForm');

    $(document).ready(function () {
        var prev: HTMLElement = null;

        document.querySelectorAll(".drop").forEach(each => {
            each.addEventListener("click", () => {
                if (prev == each) {
                    each.classList.toggle("active");
                } else {
                    for (let x = 0; x < document.querySelectorAll(".drop").length; x++) {
                        document.querySelectorAll(".drop")[x].classList.remove("active");
                    }

                    each.classList.add("active");
                }
                prev = each as HTMLElement;
            });
        });

        document.querySelector(".toTop").addEventListener("click", function () {
            $("html, body").animate({ scrollTop: 0 }, 800);
            return false;
        });

        window.onscroll = function () {
            let offset = window.pageYOffset;
            if (offset >= 50) {
                document.querySelector("header").classList.add("active");
                document.querySelector(".toTop").classList.add("active");
            } else {
                document.querySelector("header").classList.remove("active");
                document.querySelector(".toTop").classList.remove("active");
            }
        }

        document.querySelectorAll(".mobileMenuBar").forEach(each => {
            each.addEventListener("click", () => {
                each.classList.toggle("active");
            })
        });
    });
}






