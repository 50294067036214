import './home.scss';
import * as $ from 'jquery';
import 'owl.carousel/dist/owl.carousel';
import { setupLayout } from '../Shared/layout';
import '@fancyapps/fancybox';

setupLayout();

$('#carousel-1').owlCarousel({
    loop: true,
    items: 1,
    dots: false,
    autoplay: true,
    autoplayTimeout: 8000,
    navText: ["<i class='flaticon-back'></i>", "<i class='flaticon-next'></i>"],
    responsive: {
        0: {
            nav: false
        },
        1365: {
            nav: true
        }
    }
});

$('#carousel-2').owlCarousel({
    loop: true,
    margin: 16,
    dots: false,
    autoplay: true,
    autoplayTimeout: 6000,
    navText: ["<i class='flaticon-back'></i>", "<i class='flaticon-next'></i>"],
    responsive: {
        0: {
            nav: false,
            items: 1
        },
        769: {
            items: 2
        },
        1025: {
            items: 3
        }
    }
})